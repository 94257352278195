import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import productRoutes from './ProductRoutes';

import {
  prenderedRoutes,
  findIndexInData,
} from '../config/prerenderedRoutes.js';

const Confirmation = () => import('../pages/static/Confirmation');

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);

const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/Home'),
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../components/cart/Cart'),
  },
  {
    path: '/panier',
    name: 'Checkout',
    component: () => import('../components/checkout/Checkout'),
  },
  {
    path: '/confirmation',
    name: 'ConfirmationCheck',
    component: Confirmation,
  },
  {
    path: '/confirmation/:orderName',
    name: 'Confirmation',
    component: Confirmation,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/user/UserConnexion'),
  },
  {
    path: '/profil',
    name: 'Profile',
    component: () => import('../pages/user/Profile'),
  },
  {
    path: '/commande/:orderName',
    name: 'OrderDetails',
    component: () => import('../components/user/OrderDetails'),
  },
  {
    path: '/conditions-generales',
    name: 'cgu',
    component: () => import('../pages/static/Cgu'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../pages/static/Faq'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../pages/Contact'),
  },
  {
    path: '/oups',
    name: 'Oups',
    component: () => import('../pages/static/Oups'),
  },
  {
    path: '/mot-de-passe-perdu',
    name: 'forgot-password',
    component: () => import('../pages/user/UserForgotPass'),
  },
  {
    path: '/admin',
    name: 'dashboard',
    component: () => import('../pages/admin/Dashboard'),
  },
  {
    path: '/admin/carts',
    name: 'carts-admin',
    component: () => import('../pages/admin/CartsAdmin'),
  },

  {
    path: '/couleurs',
    name: 'colors',
    component: () => import('../pages/static/Colors'),
  },
  {
    path: '/admin/coupons',
    name: 'coupons-admin',
    component: () => import('../pages/admin/CouponAdmin'),
  },
  {
    path: '/admin/shipping',
    name: 'shipping-admin',
    component: () => import('../pages/admin/ShippingAdmin'),
  },
];

const router = new Router({
  mode: 'history',
  routes: [...baseRoutes, ...productRoutes],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach(async to => {
  const { getters, commit } = store;

  const index = findIndexInData(prenderedRoutes, 'name', to.path);

  if (index !== -1) {
    document.title = prenderedRoutes[index].title;
  }

  if (getters['app/getNewVersion'] !== null) {
    commit('app/setVersion', getters['app/getNewVersion']);
    commit('app/setNewVersion', null);

    Vue.nextTick(() => {
      window.location.reload(true);
    });
  }
});

export default router;
